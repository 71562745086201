<template>
  <v-dialog
    persistent
    :value="showForm"
    @input="(v) => v || HIDE_FORM()"
    max-width="600px"
    scrollable
  >
    <v-card>
      <!-- TITLE -->
      <v-card-title class="primary">
        <span class="headline">{{
          editMode ? " تعديل الاعلان" : " اضافة اعلان جديد"
        }}</span>
      </v-card-title>

      <!-- INPUTS -->
      <v-card-text class="pt-5 pb-0">
        <v-container>
          <v-progress-linear
            v-if="formLoading"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <div v-else>
            <v-text-field
              label="العنوان"
              hide-details="auto"
              class="mb-5"
              v-model="form.title"
            />
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  rounded
                  solo
                  outlined
                  hide-details
                  clearable
                  label="البلد"
                  class="mb-5 my-2"
                  item-text="country"
                  item-value="id"
                  v-model="form.country_id"
                  :error-messages="errors.country_id"
                  :items="countries"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  rounded
                  outlined
                  hide-details
                  clearable
                  solo
                  label="القسم"
                  class="mb-5 my-2"
                  v-model="form.section_id"
                  :items="sections"

                ></v-autocomplete>
              </v-col>


<!--              <v-col cols="12">-->
<!--                <v-autocomplete-->
<!--                  v-if="!editMode"-->
<!--                  rounded-->
<!--                  outlined-->
<!--                  hide-details-->
<!--                  clearable-->
<!--                  solo-->
<!--                  label="التصنيف"-->
<!--                  class="mb-5 my-2"-->
<!--                  v-model="category"-->
<!--                  :items="categories"-->
<!--                  @click="handelFirstClick"-->
<!--                  @change="fetchSubCategories(category)"-->
<!--                ></v-autocomplete>-->
<!--              </v-col>-->
<!--              <v-col cols="12">-->
<!--                <v-autocomplete-->
<!--                  v-if="!editMode"-->
<!--                  rounded-->
<!--                  outlined-->
<!--                  hide-details-->
<!--                  clearable-->
<!--                  solo-->
<!--                  label="التصنيف الفرعي"-->
<!--                  class="mb-5 my-2"-->
<!--                  v-model="form.sub_category_id"-->
<!--                  @click="handelFirstClick"-->
<!--                  :items="subCategories"-->
<!--                ></v-autocomplete>-->
<!--              </v-col>-->
<!--              <v-col cols="12">-->
<!--                <v-switch-->
<!--                  v-if="!editMode"-->
<!--                  inset-->
<!--                  :input-value="false"-->
<!--                  v-model="form.active"-->
<!--                ></v-switch>-->
<!--              </v-col>-->
              <div class="mb-5">
                <label>من تاريخ</label>
                <input type="date" v-model="form.start_date" :error-messages="errors.start_date" required/>
                <div v-if="errors.start_date" class="mt-2">
                  <span color="error">{{ errors.start_date }}</span>
                </div>

                <!-- Add vertical spacing between date inputs -->
                <div class="mb-3"></div>

                <label class="mt-3">الى تاريخ</label>
                <input type="date" v-model="form.end_date" :error-messages="errors.end_date" required/>
                <div v-if="errors.end_date" class="mt-2">
                  <span color="error">{{ errors.end_date }}</span>
                </div>
              </div>



              <v-col cols="12">
                <v-file-input
                  dense
                  outlined
                  label="الصورة [Ar]"
                  prepend-icon="mdi-image"
                  accept="image/*"
                  v-model="form.image"
                  :error-messages="errors.image"
                ></v-file-input>
                <p class="error_class">*accept JPG SIZES 1200*1600</p>
              </v-col>


              <v-col cols="12">
                <v-file-input
                  dense
                  outlined
                  label="الصورة [En]"
                  prepend-icon="mdi-image"
                  accept="image/*"
                  v-model="form.image_en"
                  :error-messages="errors.image_en"
                ></v-file-input>
                <p class="error_class">*accept JPG SIZES 1200*1600</p>
              </v-col>



              <v-col cols="6">
                <v-text-field
                  v-model="form.whatsapp"
                  label="واتساب"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.phone"
                  label="الهاتف"
                  outlined
                ></v-text-field>
                            <p>* ادخل رقم الهاتف مسبوق بكود الدولة</p>

              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.title"
                  label="عنوان الإعلان"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>

      <!-- ACTIONS -->
      <v-card-actions class="py-3">
        <v-spacer></v-spacer>
        <v-btn
          :loading="formLoading"
          color="secondary"
          elevation="1"
          dark
          @click="editMode ? update(form) : create(form)"
          >حفظ</v-btn
        >
        <v-btn color="secondary" elevation="1" dark text @click="hide()"
          >الغاء</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations , mapActions } from "vuex";

export default {
  name: "record-form",
  // components: { imageCompressor },
  data: function () {
    return {
      form: {},
      errors: {},
      formLoading: false,
      filters: {},
      countries: [],
      sections: [],
      // categories: [],
      // subCategories: [],
      section_id: "",
      // category: "",
    };
  },
  created() {
      this.fetchCountry()
      this.fetchSections();
  },
  computed: {
    ...mapState("commercialAds", {
      editMode: (state) => state.editMode,
      showForm: (state) => state.showForm,
      formRecord: (state) => {
        return {
          id: state.formRecord.id,
          start_date: state.formRecord.start_date,
          end_date: state.formRecord.end_date,
          country_id: state.formRecord.country_id,
          // sub_category_id: state.formRecord.sub_category_id,
          section_id: state.formRecord.section_id,
          title: state.formRecord.title,
          file: state.formRecord.file,
          active: state.formRecord.active,
          whatsapp: state.formRecord.whatsapp,
          phone: state.formRecord.phone,
        };
      },
    }),
  },

  watch: {
    formRecord(form) {
      this.form = form;
    },
  },

  methods: {

    ...mapMutations("commercialAds", ["HIDE_FORM"]),
    ...mapActions("commercialAds", ["fetchRecords"]),
    hide() {
      this.HIDE_FORM();
      this.section_id = "";
      // this.category = "";
    },
    handelFirstClick() {
      if (this.section_id == "") {
        swal("لابد من اختيار القسم اولا");
      }
    },
    fetchCountry() {
      this.axios.get("/admin/sliders/countries_dropdown").then((response) => {
        this.countries = response.data;
      });
    },
    // sections
    fetchSections() {
      this.axios.get("/structure/sections").then((response) => {
        for (const i of response.data) {
          this.sections.push({ value: i.id, text: i.name });
        }
      });
    },
    //category
    // fetchCategories(sectionId) {
    //   this.categories.length = 0;
    //   this.categories = [];
    //   this.axios.get(`/structure/categories/${sectionId}`).then((response) => {
    //     for (const i of response.data) {
    //       this.categories.push({ value: i.id, text: i.name });
    //     }
    //   });
    // },
    // //sub category
    // fetchSubCategories(categoryId) {
    //   this.subCategories.length = 0;
    //   this.subCategories = [];
    //   this.axios
    //     .get(`/structure/sub-categories/${categoryId}`)
    //     .then((response) => {
    //       for (const i of response.data) {
    //         this.subCategories.push({ value: i.id, text: i.name });
    //       }
    //     });
    // },

    fetchCountries() {
      this.axios.get("/admin/sliders/countries_dropdown").then((response) => {
        this.countries = response.data;
      });
    },
    create(form) {
      this.section_id = "";
      // this.category = "";
      this.formLoading = true;
      this.$store
        .dispatch("commercialAds/create", { form })
        .then((response) => {
          this.$bus.$emit("showSnackbar", {
            text: "تمت اضافة التصنيف بنجاح",
            color: "success",
          });
          this.HIDE_FORM();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          for (let key in error.response.data.errors) {
            this.$bus.$emit("showSnackbar", {
              text: error.response.data.errors[key][0],
              color: "error",
            });
          }
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords();
        });
    },

    update(form) {
      this.formLoading = true;
      // console.log({ form });
      this.$store
        .dispatch("commercialAds/update", { form })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: "Data updated",
            color: "success",
          });
          this.HIDE_FORM();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          for (let key in error.response.data.errors) {
            this.$bus.$emit("showSnackbar", {
              text: error.response.data.errors[key][0],
              color: "error",
            });
          }
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords();
        });
    },
  },
};
</script>
