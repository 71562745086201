<template>
  <div class="filter-box py-5 px-4 mb-6">
    <h3 class="primary--text mb-3">
      <v-icon class="ml-1">mdi-filter</v-icon>تصنيف حسب
    </h3>
    <v-row align="center">
      <!-- QUERY -->

      <!-- Q -->
      <v-col cols="6">
        <v-autocomplete
          rounded
          outlined
          hide-details
          clearable
          solo
          label="الدولة"
          class="mb-5 my-2"
          item-text="country"
          item-value="id"
          v-model="filters.country_id"
          :items="countries"
        ></v-autocomplete>

        <v-text-field
          label="العنوان"
          hide-details="auto"
          class="mb-5"
          v-model="filters.title"
          rounded
          outlined
        />
        <v-autocomplete
          rounded
          outlined
          hide-details
          clearable
          solo
          label="القسم"
          class="mb-5 my-2"
          v-model="filters.section_id"

          :items="sections"
        ></v-autocomplete>
<!--        <v-autocomplete-->
<!--          rounded-->
<!--          outlined-->
<!--          hide-details-->
<!--          clearable-->
<!--          solo-->
<!--          label="التصنيف"-->
<!--          class="mb-5 my-2"-->
<!--          v-model="category"-->
<!--          :items="categories"-->
<!--          @click="handelFirstClick"-->
<!--          @change="fetchSubCategories(category)"-->
<!--        ></v-autocomplete>-->
<!--        <v-autocomplete-->
<!--          rounded-->
<!--          outlined-->
<!--          hide-details-->
<!--          clearable-->
<!--          solo-->
<!--          label="التصنيف الفرعي"-->
<!--          class="mb-5 my-2"-->
<!--          v-model="filters.sub_category_id"-->
<!--          @click="handelFirstClick"-->
<!--          :items="subCategories"-->
<!--        ></v-autocomplete>-->
        <v-menu
          ref="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              rounded
              outlined
              hide-details
              clearable
              solo
              v-model="filters.created_at"
              label="اختر تاريخ الانشاء"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filters.created_at"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            "
            min="1950-01-01"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <!-- SEARCH BUTTON -->
      <v-col class="d-flex" cols="6">
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          @click="$router.replace({ query: { ...filters, page: 1 } })"
          large
          color="primary"
          >بحث</v-btn
        >
           
        <v-btn
          class="mr-2"
          @click="$router.replace({ query: { page: 1 , active:0 } })"
          large
          color="primary"
          >عرض الكل
        </v-btn>
         <v-btn
          class="mr-2"
          @click="$router.replace({ query: { page: 1 , active:1 } })"
          large
          color="primary"
        >
          عرض النشط
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { mapMutations , mapActions } from "vuex";
import swal from "sweetalert";
export default {
  name: "filters-box",

  data: function () {
    return {
      categoriesLoading: false,

      filters: {},
      countries: [],
      sections: [],
      section_id: "",
      // categories: [],
      // subCategories: [],
      // category: "",
      titles: [],
      title: "",
    };
  },

  created() {
    if (this.countries.length<=0){
      this.fetchCountry()
    }
    if (this.sections.length<=0){
      this.fetchSections();
    }
    
  },
  watch: {
    // CategoriesClickCounter(counter) {
    //   if (counter > sectionsClickCounter) {
    //   }
    // },
  },

  computed: {},

  methods: {
    ...mapActions("commercialAds",["fetchRecords"]),
    handelFirstClick() {
      if (this.section_id == "") {
        swal("لابد من اختيار القسم اولا ");
      }
    },
    ...mapMutations("ads", ["SET_FILTERS"]),

    fetchCountry() {
      this.axios.get("/admin/sliders/countries_dropdown").then((response) => {
        this.countries = response.data;
      });
    },
    // sections
    fetchSections() {

      this.axios.get("/structure/sections").then((response) => {
        for (const i of response.data) {
          this.sections.push({ value: i.id, text: i.name });
        }
      });
    },
    //category
    // fetchCategories(sectionId) {
    //    this.categories.length = 0;
    //   this.categories=[];
    //   this.axios.get(`/structure/categories/${sectionId}`).then((response) => {
    //     for (const i of response.data) {
    //       this.categories.push({ value: i.id, text: i.name });
    //     }
    //   });
    // },
    //sub category
    // fetchSubCategories(categoryId) {
    //   this.subCategories.length = 0;
    //   this.subCategories=[];
    //   this.axios
    //     .get(`/structure/sub-categories/${categoryId}`)
    //     .then((response) => {
    //       for (const i of response.data) {
    //         this.subCategories.push({ value: i.id, text: i.name });
    //       }
    //     });
    // },
  },
};
</script>
