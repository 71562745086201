var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home page"},[_c('h2',{staticClass:"accent--text"},[_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary","large":""}},[_vm._v("mdi-monitor-dashboard")]),_vm._v(" إدارة الاعلانات التجارية "),_c('back-button')],1),_c('hr',{staticClass:"my-5 header-divider"}),_c('record-form'),_c('send-notification-form'),_c('filters-box',{on:{"fetch":function($event){return _vm.fetchRecords({ page: 1 })}}}),_c('div',{staticClass:"mb-3 mt-9 text-left"},[_c('v-btn',{attrs:{"large":"","color":"primary","elevation":"0"},on:{"click":function($event){_vm.SET_FORM_RECORD({});
        _vm.SET_EDIT_MODE(false);
        _vm.SHOW_FORM();}}},[_vm._v(" اضافة إعلان تجارى جديد "),_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")])],1)],1),_c('v-chip',{staticClass:"mb-2",attrs:{"right":"","label":"","color":"secondary"}},[_c('v-icon',[_vm._v(" mdi-account-circle-outline ")]),_vm._v(" عدد الاعلانات "+_vm._s(_vm.total_commads))],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","loading":_vm.table.loading,"headers":_vm.table.headers,"items":_vm.records,"loading-text":"Downloading Data","no-data-text":"No Data","disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(ref){
        var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"item.image",fn:function(ref){
        var item = ref.item;
return [_c('img',{staticClass:"mt-3 mb-2 rounded-lg",attrs:{"src":item.image ? item.image : 'https://thumbs.dreamstime.com/b/no-image-available-icon-photo-camera-flat-vector-illustration-132483141.jpg',"width":"50px"}})]}},{key:"item.start_date",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.start_date).toLocaleTimeString([], { year: 'numeric', month:'2-digit' , day:'2-digit', hour: '2-digit', minute: '2-digit' , timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone}))+" ")]}},{key:"item.end_date",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.end_date).toLocaleTimeString([], { year: 'numeric', month:'2-digit' , day:'2-digit', hour: '2-digit', minute: '2-digit' , timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone}))+" ")]}},{key:"item.controls",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":"","color":"secondary"},on:{"click":function($event){_vm.SET_FORM_RECORD(item);
          _vm.SET_EDIT_MODE(true);
          _vm.SHOW_FORM();}}},[_c('v-icon',[_vm._v("mdi-circle-edit-outline")])],1)]}},{key:"item.send-notification",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":"","color":"secondary"},on:{"click":function($event){_vm.SET_FORM_RECORD(item);_vm.SHOW_SEND_NOTIFICATION_FORM();}}},[_c('v-icon',[_vm._v("mdi-send")])],1)]}},{key:"item.delete",fn:function(ref){
          var item = ref.item;
return [_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error"}},'v-btn',attrs,false),on),[_vm._v(" حذف ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"red","dark":""}},[_vm._v("حذف الإعلان")]),_c('v-card-text',[_c('h4',{staticClass:"my-3 red--text"},[_vm._v("هل تريد حذف هذا الإعلان")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){_vm.deleteThisCommercialAd(item.id), (dialog.value = false)}}},[_vm._v(" حذف ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("الغاء")])],1)],1)]}}],null,true)})]}},{key:"item.blocked",fn:function(ref){
          var item = ref.item;
return [(item.active == 0)?_c('v-switch',{attrs:{"inset":"","input-value":false},on:{"change":function($event){return _vm.unblock(item)}}}):_c('v-switch',{attrs:{"color":"primary","inset":"","input-value":true},on:{"change":function($event){return _vm.block(item)}}})]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"d-flex justify-end",attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"error"},on:{"click":_vm.deleteSelected}},[_vm._v("حذف المحدد")])],1)]},proxy:true}],null,true)}),_c('div',{staticClass:"text-center mt-7"},[_c('v-pagination',{attrs:{"total-visible":"7","value":parseInt(_vm.filters.page),"length":_vm.totalPages,"circle":""},on:{"input":function($event){return _vm.$router.replace({ query: Object.assign({}, _vm.$route.query, {page: $event}) })}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }